import * as PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import { config, library } from "@fortawesome/fontawesome-svg-core"
import * as fam from "lib/fontAwesome"
import { init as initSentry } from "scripts/Sentry"
import { init as initAxios } from "lib/withAxios"
import GlobalStyle from "components/styled/GlobalStyles"
import "@fortawesome/fontawesome-svg-core/styles.css"
import "../src/index.css"

config.autoAddCss = false
library.add(fam)
initSentry()
initAxios()

const theme = {
  colors: {
    primary: "#ee4c14",
    secondary: "#1f8efa",
    softOrange: "#eb8a23",
    softGreen: "#05e2e0",
    dark: "#242e42",
    greyBlue: "#6780aa",
    clearBlue: "#1f8efa",
    lightGreenishBlue: "#6decb9",
    navyBlue: "#0f4aa0",
    blue: "#1c67e5",
    yellow: "#ffbe0b",
    pink: "#e83f94",
    darkGrey: "#9b9eb1",
    grey: "#e1e6ee",
    lightGrey: "#f7f7fa",
    white: "#ffffff",
    crimson: "#e60d42",
    watermelon: "#fe4164",
    dusk: "#3c4c69",
    scBlue: "#72adf1",
    purple: "#c162f9",
    success: "#4caf50",
    veryLightBlue: "#e1e6ee",
    lightMint: "#bcffc5"
  }
}

export default function MyApp(props) {
  const { Component, pageProps, err } = props

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Component {...pageProps} err={err} />
      </ThemeProvider>
    </>
  )
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired
}
