import axios from "axios"
import * as Sentry from "@sentry/browser"
import { isEmpty } from "lodash"

export function init() {
  axios.interceptors.response.use(
    response => response.data,
    error => {
      let jsonResponse = { httpStatus: error.request.status }
      const data = {
        request: error.config.data,
        response: error.request.response
      }
      if (error.request.status >= 500) {
        // eslint-disable-next-line no-undef
        Sentry.captureException("Server down", error, data)
        // eslint-disable-next-line no-undef
        return Promise.reject({})
      }
      try {
        if (!isEmpty(error.request.response)) {
          const response = JSON.parse(error.request.response)
          jsonResponse = { ...response.errors, httpStatus: error.request.status }
        }
      } catch (e) {
        // eslint-disable-next-line no-undef
        Sentry.captureException("Error is not JSON", error, data)
      }
      // eslint-disable-next-line no-undef
      return Promise.reject(jsonResponse)
    }
  )
}
